/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html,
body {
    height: 100%;
    width: 100%;
    position: fixed;
}
body {
    margin: 0;
    overflow: hidden;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary:hover {
    background: #0f74f0;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary:active {
    background: #094690;
}

@font-face {
    font-family: Ubuntu; //ubuntu-regular
    src: url(./assets/fonts/Ubuntu-R.ttf) format("TrueType");
}

* {
    font-family: Ubuntu, "Helvetica Neue", sans-serif;
}
.mat-grid-tile .mat-grid-tile-content {
    justify-content: flex-start !important;
    
 }
 

.notify-success{
    background-color: green;
}

.notify-warning {
    background-color: goldenrod;
    color: black;
}

.notify-error{
    background-color: crimson;
}

@keyframes spinner {
    to {transform: rotate(360deg);}
  }
  
  .spinner:before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid blue;
    border-top-color: #ffffff;
    animation: spinner .8s linear infinite;
  }

  .full-width {
    width: 100%;
  }

  .text-left {
    text-align: left !important;
}

  .text-right {
    text-align: right !important;
}